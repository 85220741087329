// function to retrieve the grid data around the category side nav
export function getProductGridItemCategoryNavSettings({
  node,
  routeInfo,
  categoryNav,
}) {
  let breadcrumbs;

  let activeCategory = {
    ancestors: [],
    siblings: [],
    isTopLevel: true,
    label: 'category',
    isResourceBundle: false,
    subcategories: [],
    urlPath: '/',
  };

  let shouldShowCategoryHeading =
    node.customFields?.shouldShowCategoryHeading || false;
  let shouldShowCategoryDropdown =
    node.customFields?.shouldShowCategoryDropdown || false;

  if (shouldShowCategoryDropdown) {
    ({ activeCategory, breadcrumbs } = categoryNav);

    // if we did not find an active category, do not try to render the side navigation
    if (!activeCategory || !breadcrumbs?.length) {
      shouldShowCategoryHeading = false;
      shouldShowCategoryDropdown = false;
    }
  }
  return {
    activeCategory,
    breadcrumbs,
    shouldShowCategoryHeading,
    shouldShowCategoryDropdown,
  };
}
