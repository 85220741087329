import { isFabletics } from './brandNameHelper';

function isYittyOnlySize(size) {
  return size === '5X' || size === '6X';
}

function fabletifySizeFilter(sizes) {
  return [
    ...new Set(
      sizes.map((size) =>
        isYittyOnlySize(size) && isFabletics() ? '4X' : size
      )
    ),
  ];
}

export function resolveSizeFilters(filters) {
  const {
    size_top: sizeTop,
    size_bottom: sizeBottom,
    size_bra: sizeBra,
  } = filters.aggregationFilter;
  if (sizeTop) {
    filters.aggregationFilter.size_top = fabletifySizeFilter(sizeTop);
  }
  if (sizeBottom) {
    filters.aggregationFilter.size_bottom = fabletifySizeFilter(sizeBottom);
  }
  if (sizeBra) {
    filters.aggregationFilter.size_bra = fabletifySizeFilter(sizeBra);
  }
}
