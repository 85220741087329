import { productCategoryFilter } from '../constants/productGrid';

import { addCustomFilterSettings } from './addCustomFilterSettings';

export const addCategoryFilterSetting = addCustomFilterSettings(
  'default_product_category_id',
  productCategoryFilter,
  (aggregation, override) => {
    // Filter out any category aggregations from the override that weren't returned
    // in the aggregation.items array. Ensures we only show category
    // filters for the actual product categories present in the grid.
    if (aggregation && aggregation.items) {
      const returnedAggregrationValues = new Set(
        aggregation.items.map(({ value }) => parseInt(value, 10))
      );

      const filteredItems = override.items.filter(({ value }) =>
        returnedAggregrationValues.has(value)
      );

      return {
        ...override,
        items: filteredItems,
      };
    }
    return override;
  }
);
