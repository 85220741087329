// Get the profile size filters that we would want to apply to the filters we already have
// so that we can then merge them in later. We can't merge them in immediately because we
// need to know which profile filters we're actually to correctly set our URL filter parameters.
export function getProfileSizeFiltersToApply(profileSizes, initialFilters) {
  return initialFilters.aggregations?.reduce((filters, aggregation) => {
    const { field } = aggregation;
    const hasExistingValue =
      initialFilters.aggregationFilter[field] != null &&
      initialFilters.aggregationFilter[field].length > 0;

    // If there are already filters applied to this field, we shouldn't
    // touch it.
    if (!hasExistingValue) {
      const profileValue = profileSizes[field];
      if (profileValue != null) {
        filters = {
          ...filters,
          [field]: [profileValue],
        };
      }
    }

    return filters;
    // If there aren't any filters to auto-apply, just return null.
  }, null);
}
