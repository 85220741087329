import remove from 'lodash/remove';
import Router from 'next/router';

import getAutoAppliedInitialFilters from '~/grid/utils/getAutoAppliedInitialFilters';
import {
  fplSortOption,
  defaultSortOptions,
  parseSortOptionsFromSortList,
} from '~/shared/constants/productGrid';
import { builderAutomaticSizeConfigValues } from '~/shared/constants/productSizes';
import { SecondarySites } from '~/shared/constants/secondarySites';
import { addCategoryFilterSetting } from '~/shared/utils/addCategoryFilterSetting';
import { isYitty } from '~/shared/utils/brandNameHelper';
import { getDefaultProductFilters } from '~/shared/utils/getDefaultProductFilters';
import { getProfileSizeFilters } from '~/shared/utils/getProfileSizeFilters';
import { modifyFiltersForLastChance } from '~/shared/utils/modifyFiltersForLastChance';
import { resolveSizeFilters } from '~/shared/utils/resolveSizeFilters';
import { getSecondarySite } from '~/shared/utils/useSecondarySite';
import { parseProductListingQueryParamsCustomFilters } from '~/techstyle-shared/react-products';

import { getProductGridItemFilters } from './getProductGridItemFilters';

export function shouldApplyAutoSizeFilteringByBuilderConfig({
  builderAutomaticSizeFiltering,
  gridGender,
  customerGender,
} = {}) {
  if (
    builderAutomaticSizeFiltering === builderAutomaticSizeConfigValues.DISABLED
  ) {
    return false;
  }

  if (!gridGender || !customerGender || !builderAutomaticSizeFiltering) {
    return false;
  }

  if (
    builderAutomaticSizeFiltering ===
    builderAutomaticSizeConfigValues.USE_FEATURE_FLAG
  ) {
    return false;
  }

  return gridGender === customerGender;
}

// function to process the grid filters and the sort options
// notes: sort options should soon fully be managed inside of builder
// and this function should simplify. also, grid sort feature and the
// other complexities should hopefully disappear from here as a side
// effect of the builder management of sort options
export async function getProductGridItemFiltersAndSortOptions({
  node,
  rewriteSort,
  gridSortFeatureData,
  displayRetailPrice,
  query,
  customer,
  gender,
  categoryPath,
  genderAutoFilterEnabled,
  genderFilterString,
  membership,
  tld,
  automaticSizeFiltering, // automatic size filtering page config from builder
  disposition,
}) {
  const secondarySite = getSecondarySite(Router.asPath);

  // rewrite sort per grid config
  if (node.gridSortFeature && !rewriteSort) {
    rewriteSort = gridSortFeatureData?.value?.sort;

    /* we are supporting rewriteSort being a string or an array
      example feature configurations that would use sort:
      {"value": "bestsellers", "sort": "bestsellers"}

      or

      {"sort": [{"field": "sort_list_0029", "direction": "asc"}]}

      if it's an array, we need to dereference it so it's mutable
    */
    if (Array.isArray(rewriteSort)) {
      rewriteSort = [...rewriteSort];
    }
  }

  const baseFilters = getProductGridItemFilters(node);
  let sortOptions = defaultSortOptions({
    retailPriceSort: displayRetailPrice,
    secondarySite,
  });
  let initialSortOption = sortOptions[0];

  // fpl sort logic
  const isFplSort = baseFilters.fplCodes?.length > 0;

  // handler for the sort list builder input
  if (node.sortList?.length) {
    sortOptions = parseSortOptionsFromSortList({
      sortList: node.sortList,
      retailPriceSort: displayRetailPrice,
      secondarySite,
    });
    if (sortOptions?.length) {
      initialSortOption = sortOptions[0];
    }
  }

  // this fpl sort logic is deprecated if sortList is used
  if (!node.sortList?.length) {
    // fpl sort logic
    initialSortOption = isFplSort
      ? fplSortOption
      : sortOptions.find((option) => option.isDefault) || sortOptions[0];

    if (
      // grid sort feature will be deprecated via AB testing in builder and sortList
      isFplSort &&
      !sortOptions.some((option) => option.value === fplSortOption.value)
    ) {
      sortOptions = addNewDefaultSortOption(fplSortOption, sortOptions);
    }
  }

  let { filters: initialFilters, sortOption: urlSortOption } =
    parseProductListingQueryParamsCustomFilters(query, {
      baseFilters,
      filterSettings: addCategoryFilterSetting(baseFilters.aggregations),
      sortOptions,
    });

  // The sort from the rewrites will take over our best sellers
  if (rewriteSort) {
    const { field: rewriteValue = '' } = rewriteSort[0];
    const bestSellersIndex = sortOptions.findIndex(
      (option) => option.defaultMessage === 'Best Sellers'
    );
    if (bestSellersIndex !== -1) {
      const rewriteSortOption = {
        ...sortOptions[bestSellersIndex],
        value: rewriteValue,
        sort: { ...rewriteSort },
      };
      initialSortOption = sortOptions[bestSellersIndex] = rewriteSortOption;
    } else {
      sortOptions = addNewDefaultSortOption(
        {
          value: rewriteValue,
          sort: { ...rewriteSort },
          defaultMessage: 'Best Sellers',
          isDefault: true,
        },
        sortOptions
      );
      initialSortOption = sortOptions[0];
    }
  }

  // this sort list is deprecated if sortList is used
  if (!node.sortList) {
    if (node?.sort?.field) {
      const algoSortOption = {
        value: node.sort.field,
        sort: [node.sort],
        isDefault: true,
        defaultMessage: node?.sort?.filterLabel || 'Default',
      };

      sortOptions = removeDuplicateSortOptionByMessage(
        sortOptions,
        algoSortOption.defaultMessage
      );
      sortOptions = addNewDefaultSortOption(algoSortOption, sortOptions);

      initialSortOption = sortOptions[0];
    }
  }

  if (urlSortOption) {
    initialSortOption = urlSortOption;
  }

  const profileSizes = getProfileSizeFilters(customer, gender);

  let gridGender = null;
  if (baseFilters?.gridGender === 'male') {
    gridGender = 'M';
  }

  if (baseFilters?.gridGender === 'female') {
    gridGender = 'F';
  }

  const isAutomaticSizeFilteringConfigSetInBuilder = Boolean(
    automaticSizeFiltering &&
      automaticSizeFiltering !==
        builderAutomaticSizeConfigValues.USE_FEATURE_FLAG
  );

  const shouldApplyProfileSizes = getShouldApplyProfileSizes({
    automaticSizeFiltering,
    categoryPath,
    gender,
    gridGender,
    isAutomaticSizeFilteringConfigSetInBuilder,
    query,
    secondarySite,
  });

  const autoAppliedInitialFiltersRetVal = getAutoAppliedInitialFilters({
    initialFilters,
    profileSizes,
    shouldApplyProfileSizes,
    automaticSizeConfig: {
      builderAutomaticSizeFiltering: automaticSizeFiltering,
      customerGender: gender,
    },
  });

  const autoAppliedInitialFilters =
    autoAppliedInitialFiltersRetVal.autoAppliedFilters;
  initialFilters = autoAppliedInitialFiltersRetVal.initialFilters;

  // automatically apply gender filter based on session/customer gender
  if (
    genderAutoFilterEnabled &&
    genderFilterString &&
    initialFilters?.aggregationFilter?.gender &&
    !initialFilters.aggregationFilter.gender.length &&
    ['female', 'male'].indexOf(genderFilterString) >= 0
  ) {
    initialFilters.aggregationFilter.gender.push(genderFilterString);
  }

  // if we have an fpl sort do not use the boosted fpl id
  if (isFplSort) {
    initialFilters.ignoreBoostedFpl = true;
    baseFilters.ignoreBoostedFpl = true;
  } else {
    initialFilters.ignoreBoostedFpl = false;
    baseFilters.ignoreBoostedFpl = false;
  }

  // set up query options
  const options = {};
  if (initialFilters.excludePreorderProducts != null) {
    baseFilters.excludePreorderProducts =
      initialFilters.excludePreorderProducts;
  }

  if (disposition) {
    initialFilters.disposition = {
      offer: disposition.offer,
      gender: disposition.gender,
    };
    baseFilters.disposition = {
      offer: disposition.offer,
      gender: disposition.gender,
    };
  }

  if (node?.customFields?.showHiddenProducts) {
    options.showHiddenProducts = node.customFields.showHiddenProducts;
  }

  const filters = getDefaultProductFilters(
    membership,
    tld,
    initialFilters,
    options
  );

  resolveSizeFilters(initialFilters);

  modifyFiltersForLastChance({
    filters: initialFilters,
    alwaysShowLastChance: node?.showLastChance || false,
  });

  return {
    initialSortOption,
    sortOptions,
    baseFilters,
    initialFilters,
    filters,
    autoAppliedInitialFilters,
  };
}

const addNewDefaultSortOption = (option = {}, sortOptions = []) => {
  return [
    option,
    ...sortOptions.map((option) => ({
      ...option,
      isDefault: false,
    })),
  ];
};

const removeDuplicateSortOptionByMessage = (sortOptions = [], message = '') => {
  const newSortOptions = remove(sortOptions, (sortOption) => {
    return sortOption.defaultMessage !== message;
  });

  return newSortOptions;
};

const getShouldApplyProfileSizes = ({
  automaticSizeFiltering,
  categoryPath,
  gender,
  gridGender,
  isAutomaticSizeFilteringConfigSetInBuilder,
  query,
  secondarySite,
}) => {
  if (secondarySite === SecondarySites.OUTLET) {
    return false; // do not apply auto filters on outlet site
  }
  if (isAutomaticSizeFilteringConfigSetInBuilder) {
    return shouldApplyAutoSizeFilteringByBuilderConfig({
      builderAutomaticSizeFiltering: automaticSizeFiltering,
      gridGender,
      customerGender: gender,
    }); // if auto filter logic is set in builder, use the builder config
  }
  return !getShouldDisableSizeAutoFilteringByFeatureFlag({
    categoryPath,
    gender,
    query,
  }); // otherwise use the logic based on feature flag, gender, and url path
};

const getShouldDisableSizeAutoFilteringByFeatureFlag = ({
  categoryPath,
  gender,
  query,
}) => {
  if (isYitty()) {
    return true;
  }

  const excludePathForSizeFiltering = !(
    categoryPath?.pathname?.includes('/womens') ||
    categoryPath?.pathname?.includes('/mens') ||
    query.gender === 'womens' ||
    query.gender === 'mens'
  );

  // allow control over grid default filtering on grids for genders
  // based on filter flags
  const gridUrlIsMens =
    categoryPath?.pathname?.includes('/mens') || query.gender === 'mens';
  const gridUrlIsWomens =
    categoryPath?.pathname?.includes('/womens') || query.gender === 'womens';

  const removeSizeFiltersForMenAsWoman = gridUrlIsMens && gender === 'F';
  const removeSizeFiltersForWomenAsMan = gridUrlIsWomens && gender === 'M';
  const removeSizeFiltersForMen = gridUrlIsMens && gender === 'M';

  return (
    excludePathForSizeFiltering ||
    removeSizeFiltersForWomenAsMan ||
    removeSizeFiltersForMenAsWoman ||
    removeSizeFiltersForMen
  );
};
