export const addCustomFilterSettings = (
  filterFieldName,
  override,
  filterOverride = (orig, override) => override
) => {
  return (aggregations = []) => {
    return aggregations.map((aggregation) => {
      return aggregation.field === filterFieldName
        ? filterOverride(aggregation, override)
        : aggregation;
    });
  };
};
