import { gql } from '~/techstyle-shared/react-graphql';

const PRICING_FIELDS = `
  final
  finalDetails {
    displayOnPdp
    promoCode
    promoLabel
    promoPrice
    promoTypeId
    __typename
  }
  masterProductId
  sales
  vip
  retail
  outlet
  __typename
  pricingDatasource {
      sourceUrl
      version
  }
`;

const IMAGE_FIELDS = `
  angle
  sizes {
    height
    width
    url
    __typename
  }
  __typename
`;

const SWATCH_FIELDS = `
  isSelf
  label
  url
  productId
  __typename
`;

const RELATED_PRODUCTS_FIELDS = `
  color
  dateExpected
  isAvailable
  isSelf
  itemNumber
  permalink
  masterProductId
  isWarehouseProduct
  tags {
    id
  }
  images {
    default {
      ${IMAGE_FIELDS}
    }
    plus {
      ${IMAGE_FIELDS}
    }
  }
  __typename
`;

const BASE_PRODUCT_FIELDS = `
  alias
  color
  componentProductIdObject {
    color
    componentProductId
    groupCode
    isSelf
    priceContributionPercentage
    __typename
  }
  dateExpected
  fpl {
    id
    code
    __typename
  }
  itemNumber
  label
  masterProductId
  membershipBrandId
  permalink
  categories {
    id
    label
    __typename
  }
  images {
    default {
      ${IMAGE_FIELDS}
    }
    plus {
      ${IMAGE_FIELDS}
    }
  }
  productTypeId
  relatedProducts {
    ${RELATED_PRODUCTS_FIELDS}
  }
  swatches {
    ${SWATCH_FIELDS}
  }
  tags {
    id
    label
    __typename
  }
  waitListAllowed
  reviewsSummary {
    averageRating
    __typename
  }
  availableQuantity {
    anyProfile
    master
    __typename
  }
  defaultProductCategory {
    label
    __typename
  }
  tokenSummary {
    isToken
    redemptionQty
    __typename
  }
`;

const PRODUCT_LISTING_FRAGMENT = `
  productListing(
    from: $from
    productQuery: $productQuery
    size: $size
    sort: $sort
  ) {
    products {
      ${BASE_PRODUCT_FIELDS}
      __typename
    }
    size
    total {
      relation
      value
      __typename
    }
    from
    __typename
  }
`;

const FULL_PRODUCT_LISTING_FRAGMENT = `
  productListing(
    from: $from
    productQuery: $productQuery
    size: $size
    sort: $sort
  ) {
    products {
      ${BASE_PRODUCT_FIELDS}
      pricing(retailDiscounting: $retailDiscounting) {
        ${PRICING_FIELDS}
      }
      __typename
    }
    size
    total {
      relation
      value
      __typename
    }
    from
    __typename
  }
`;

const PRODUCT_PRICING_LISTING_FRAGMENT = `
  productListing(
    from: $from
    productQuery: $productQuery
    size: $size
    sort: $sort
  ) {
    products {
      label
      masterProductId
      pricing(retailDiscounting: $retailDiscounting) {
        ${PRICING_FIELDS}
      }
      __typename
    }
    size
    total {
      relation
      value
      __typename
    }
    from
    __typename
  }
`;

const PRODUCT_RELATED_PRODUCTS_FRAGMENT = `
  productListing(
    from: $from
    productQuery: $productQuery
    size: $size
    sort: $sort
  ) {
    products {
      label
      masterProductId
      relatedProducts(
        limit: 6,
        filter: { isWarehouseProduct: $shouldShowWarehouseProducts }
      ) {
        ${RELATED_PRODUCTS_FIELDS}
      }
      swatches {
        ${SWATCH_FIELDS}
      }
      __typename
    }
    size
    total {
      relation
      value
      __typename
    }
    from
    __typename
  }
`;

export const getFullProductsQuery = (productDimensionFragment) => gql`
  query ProductListing(
    $productQuery: ProductQuery
    $size: Int
    $sort: Sort
    $from: Int
    $retailDiscounting: Boolean
    $dimensionsProductQuery: ProductQuery
  ) {
    ${FULL_PRODUCT_LISTING_FRAGMENT}
    ${productDimensionFragment}
  }
`;

export const getBaseProductsQuery = (productDimensionFragment) => gql`
  query ProductListing(
    $productQuery: ProductQuery
    $size: Int
    $sort: Sort
    $from: Int
    $dimensionsProductQuery: ProductQuery
  ) {
    ${PRODUCT_LISTING_FRAGMENT}
    ${productDimensionFragment}
  }
`;

export const BASE_PRODUCTS_PRICING_QUERY_BFF = gql`
  query ProductPricing(
    $productQuery: ProductQuery
    $size: Int
    $sort: Sort
    $from: Int
    $retailDiscounting: Boolean
  ) {
    ${PRODUCT_PRICING_LISTING_FRAGMENT}
  }
`;

export const BASE_PRODUCTS_PRICING_QUERY_PRICING_SERVICE = gql`
  query SearchGridProductPricing(
    $masterProductIds: [Int]
    $membershipStatus: Int
  ) {
    productPricing(
      masterProductIds: $masterProductIds
      membershipStatus: $membershipStatus
    ) {
      ${PRICING_FIELDS}
    }
  }
`;

export const BASE_PRODUCTS_RELATED_PRODUCTS_QUERY = gql`
  query RelatedProducts(
    $productQuery: ProductQuery
    $size: Int
    $sort: Sort
    $from: Int
    $shouldShowWarehouseProducts: Boolean
  ) {
    ${PRODUCT_RELATED_PRODUCTS_FRAGMENT}
  }
`;
