import { builderAutomaticSizeConfigValues } from '~/shared/constants/productSizes';
import { getProfileSizeFiltersToApply } from '~/shared/utils/getProfileSizeFiltersToApply';
import { getSizesWithNeighbors } from '~/shared/utils/getSizesWithNeighbors';

export default function getAutoAppliedInitialFilters({
  initialFilters,
  profileSizes,
  shouldApplyProfileSizes = true,
  automaticSizeConfig = {},
}) {
  let autoAppliedFilters = null;

  if (profileSizes && shouldApplyProfileSizes) {
    autoAppliedFilters = getProfileSizeFiltersToApply(
      profileSizes,
      initialFilters
    );

    if (
      autoAppliedFilters &&
      automaticSizeConfig?.customerGender &&
      automaticSizeConfig?.builderAutomaticSizeFiltering ===
        builderAutomaticSizeConfigValues.PROFILE_SIZE_WITH_UP_DOWN
    ) {
      const result = {};
      Object.keys(autoAppliedFilters).forEach((key) => {
        const size = autoAppliedFilters[key][0];
        result[key] = getSizesWithNeighbors({
          size,
          gender: automaticSizeConfig.customerGender,
        });
      });
      autoAppliedFilters = result;
    }

    return {
      autoAppliedFilters,
      initialFilters: autoAppliedFilters
        ? {
            ...initialFilters,
            aggregationFilter: {
              ...initialFilters.aggregationFilter,
              ...autoAppliedFilters,
            },
          }
        : initialFilters,
    };
  }

  return {
    autoAppliedFilters,
    initialFilters,
  };
}
