export const AggregationPairingsMap = new Map([
  ['activity', 'activity'],
  ['size_bottom', 'bottomSize'],
  ['size_clothing', 'clothingSize'],
  ['collection', 'collection'],
  ['color', 'color'],
  ['compression', 'compression'],
  ['size_denim', 'denimSize'],
  ['dressstyle', 'dressStyle'],
  ['dressesstyle', 'dressStyle'],
  ['dress_style', 'dressStyle'],
  ['fabric', 'fabric'],
  ['feature', 'feature'],
  ['length', 'length'],
  ['occasion', 'occasion'],
  ['review', 'review'],
  ['rise', 'rise'],
  ['size', 'size'],
  ['size_all_gender_bottom', 'sizeAllGenderBottom'],
  ['size_all_gender_top', 'sizeAllGenderTop'],
  ['size_bra', 'sizeBra'],
  ['size_bralette', 'sizeBralette'],
  ['size_lingerie', 'sizeLingerie'],
  ['size_sport', 'sizeSport'],
  ['size_undie', 'sizeUndie'],
  ['size_shoes', 'shoeSize'],
  ['size_top', 'topSize'],
  ['sleeve_length', 'sleeveLength'],
  ['style', 'style'],
  ['trend_detail', 'trendDetail'],
]);
