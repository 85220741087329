import config from 'config';

import { createSelector } from '~/techstyle-shared/redux-core';

import { ProductFPL, ProductTag } from '../constants/products';

import { isSavage as getIsSavage } from './brandNameHelper';

const isSavage = getIsSavage();

export const getDefaultProductFilters = createSelector(
  [
    (membership, tld) => membership.membershipLevelGroup,
    (membership, tld, baseFilters) => baseFilters,
    (membership, tld, baseFilters, options) => options,
    (membership, tld) => membership.isVip,
    (membership, tld) => tld,
  ],
  (membershipLevelGroup, baseFilters, options = {}, isVip, tld) => {
    const {
      // eslint-disable-next-line no-unused-vars
      allowStickySoldOut = config.get('public.brand.allowStickySoldOut'),
      boostedFplBy = 'membership',
      filterByMembershipLevelGroup = true,
      showPreorder = true,
      showHiddenProducts = false,
      // SXF Options
      includeFplOnly = false,
      includeCollectible = false,
      autoExcludeClearanceFplIds = true,
    } = options;

    let {
      availableQuantityIgnoreTagIds = [],
      availableQtyMin,
      boostedFplId,
      excludeFpls = [],
      excludeTagIds = [],
      includeOutOfStock,
      ignoreBoostedFpl = false,
    } = baseFilters;

    const excludeFplsSet = new Set(excludeFpls);
    const excludeTagIdsSet = new Set(excludeTagIds);

    if (allowStickySoldOut) {
      if (!availableQuantityIgnoreTagIds.includes(ProductTag.stickySoldOut)) {
        availableQuantityIgnoreTagIds = [
          ...availableQuantityIgnoreTagIds,
          ProductTag.stickySoldOut,
        ];
      }
    }

    if (includeOutOfStock === false) {
      availableQtyMin = Math.max(availableQtyMin || 1, 1);
    }

    if (!showHiddenProducts && !isSavage) {
      excludeFplsSet.add(ProductFPL.hidden);
      excludeTagIdsSet.add(ProductTag.altOutfit);
      excludeTagIdsSet.add(ProductTag.hidden);
    }

    if (isSavage) {
      if (!showHiddenProducts) {
        const hideFromGridFpl =
          ProductFPL.hideFromGrid[tld] || ProductFPL.hidden;
        excludeFplsSet.add(hideFromGridFpl);
      }

      if (!includeFplOnly) {
        excludeTagIdsSet.add(ProductTag.fplOnly);
      }

      if (!includeCollectible) {
        excludeTagIdsSet.add(ProductTag.collectible);
      }

      if (isVip) {
        excludeTagIdsSet.add(ProductTag.leadOnly);
      } else {
        excludeTagIdsSet.add(ProductTag.vipOnly);
      }

      if (autoExcludeClearanceFplIds) {
        const leadFplId = ProductFPL.leadSaleFpls[tld];
        const vipFplId = ProductFPL.vipSaleFpls[tld];
        if (isVip) {
          excludeFplsSet.add(vipFplId);
        } else {
          excludeFplsSet.add(leadFplId);
        }
      }
    }

    // If boostedFplBy is explicitly null, don't pin to top, otherwise default
    // to membership-based pinnedFplId.
    if (
      !ignoreBoostedFpl &&
      typeof boostedFplId === 'undefined' &&
      boostedFplBy
    ) {
      const pinnedFplByDomain = config.get('public.pinnedFplIds');
      const pinnedFplIds = pinnedFplByDomain[tld];

      if (pinnedFplIds) {
        switch (boostedFplBy) {
          case 'membership':
            boostedFplId = isVip ? pinnedFplIds.vip : pinnedFplIds.lead;
            break;
          case 'global':
            boostedFplId = pinnedFplIds.global;
            break;
        }
      }
    }

    const shouldExcludePreorder =
      baseFilters.excludePreorderProducts != null
        ? baseFilters.excludePreorderProducts
        : !showPreorder;

    return {
      ...baseFilters,
      ...(typeof availableQtyMin !== 'undefined' && { availableQtyMin }),
      ...(typeof boostedFplId !== 'undefined' && { boostedFplId }),
      availableQuantityIgnoreTagIds,
      excludeFpls: Array.from(excludeFplsSet),
      excludePreorderProducts: shouldExcludePreorder,
      excludeTagIds: Array.from(excludeTagIdsSet),
      ...(filterByMembershipLevelGroup && { membershipLevelGroup }),
      ...(typeof includeOutOfStock !== 'undefined' && { includeOutOfStock }),
      additionalFields: [
        'membership_brand_id',
        ...(baseFilters.additionalFields ? baseFilters.additionalFields : []),
      ],
    };
  }
);
