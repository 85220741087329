import config from 'config';

import { PromoType } from '~/techstyle-shared/react-promos';

export const ProductCategory = config.get('public.productCategories');
export const ProductTag = config.get('public.productTags');
export const ProductFPL = config.get('public.productFpls');

export const BestSellers = {
  Mens: [
    ProductCategory.mensBottom,
    ProductCategory.mensOutfit,
    ProductCategory.mensTop,
  ],
  Womens: [
    ProductCategory.womensTop,
    ProductCategory.womensFashionBottom,
    ProductCategory.womensBra,
    ProductCategory.womensOutfit,
  ],
};

/**
 * We are attempting to limit the amount of data returned from products/v2.
 * This list was composed by setting up a proxy object in reactui
 * ProductContext to asses which properties are needed for grids.
 *
 * Properties of NOTE:
 * - Lots of fields added because of tracking. See `getTrackProductDetails`.
 * - related_product_id_object_list: we only check if length > 1 so we are
 *   only requesting the first 2 if available
 *   - reactui/MoreColorsLabel
 * - product_id_object_list[x].available_quantity
 *   - callout messaging, low-stock
 * - product_id_object_list[x].available_quantity_preorder
 *   - callout messaging, preorder
 *
 */
export const DEFAULT_GRID_PRODUCT_FIELDS = [
  'active_featured_product_location_code_list',
  'active_featured_product_location_id_list',
  'alias',
  'available_quantity_any_profile',
  'available_quantity_master',
  'average_review',
  'color',
  'component_product_id_object_list',
  'date_expected',
  'date_preorder_expires',
  'default_product_category_label',
  'default_unit_price',
  'featured_product_location_id_list',
  'item_number',
  'label',
  'master_product_id',
  'membership_brand_id',
  'permalink',
  'product_category_id_list',
  'product_images',
  'product_type_id',
  'promos',
  'related_product_id_object_list.[0]',
  'related_product_id_object_list.[1]',
  'related_product_id_object_list.[2]',
  'related_product_id_object_list.[3]',
  'related_product_id_object_list.[4]',
  'related_product_id_object_list.[5]',
  'retail_unit_price',
  'sale_unit_price',
  'swatch_url',
  'tag_id_list',
  'token_redemption_quantity',
  'wait_list_allowed',
  'is_token',
  'style_code',
  'style_label',
  'warehouse_unit_price',
];

export const DEFAULT_GRID_ES_QUERY_FIELDS = [
  ...DEFAULT_GRID_PRODUCT_FIELDS,
  'image_view_list',
  'related_product_id_list',
  'related_product_id_object_list.color',
  'related_product_id_object_list.item_number',
  'related_product_id_object_list.related_product_id',
  'related_product_id_object_list.permalink',
  'related_product_id_object_list.date_expected',
  'related_product_id_object_list.is_self',
  'related_product_id_object_list.is_available',
  'related_product_id_object_list.tag_id_list',
  'related_product_id_object_list.is_warehouse_product',
  'variant_pricing_id_object_list',
];

export const promoPriceHiddenPromoTypes = new Set([
  PromoType.BOGO,
  PromoType.BOGO_WITH_DISCOUNT,
  PromoType.BUY_X_GET_Y_OFF,
  PromoType.BUY_X_GET_EACH_Y,
]);

export const promoPriceNoStrikeThrough = new Set([
  PromoType.EMBROIDERY_DISCOUNT,
]);
