import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import {
  FilterTagsCustomFilters,
  getAvgReviewFilter,
  LabelType,
  useOptionLabels,
  useProductFiltersCustomFilters,
} from '~/techstyle-shared/react-components';

import { SecondarySites } from './secondarySites';

export const imageAspectRatio = 7 / 10;
export const videoAspectRatio = 1.4;
export const pageSize = 24;
export const overlayColor = 'rgba(0, 0, 0, 0.5)';
export const gridColumnSettings = config.get('public.brand.gridColumnSettings');

export const avgReviewFilter = {
  ...getAvgReviewFilter(),
  field: 'avgReviewMin',
  label: 'Reviews',
  items: [
    { value: 4, label: '4.0 & Up' },
    { value: 3, label: '3.0 & Up' },
  ],
};

export const carouselGridCarouselOptions = {
  contain: true,
  wrapAround: false,
  pageDots: false,
  adaptiveHeight: false,
  autoPlay: false,
  prevNextButtons: false,
  cellAlign: 'left',
  initialIndex: 0,
};

export const breakpointPropType = PropTypes.shape({
  pictureWidth: PropTypes.number,
  columns: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  mediaQuery: PropTypes.string,
});

export const breakpoints = [
  {
    name: 'mobile',
    ...gridColumnSettings.mobile,
    minWidth: 0,
  },
  {
    name: 'tablet',
    ...gridColumnSettings.mobile,
    minWidth: 375,
  },
  {
    name: 'tablet+',
    ...gridColumnSettings.desktop,
    minWidth: 960,
  },
  {
    name: 'desktop',
    ...gridColumnSettings.desktop,
    minWidth: 1200,
  },
  {
    name: 'desktop+',
    ...gridColumnSettings.desktop,
    minWidth: 1400,
  },
];

const ProductCategoryFilterTag = ({
  filterSetting,
  selectedFilters,
  ...rest
}) => {
  const optionLabelCtx = useOptionLabels();
  const { setSelectedFilters } = useProductFiltersCustomFilters();

  const labelOptions = {
    labelType: LabelType.PRODUCT_FILTER_TAG,
  };
  const selectedOptions = filterSetting.items.filter((item) =>
    selectedFilters.aggregationFilter.default_product_category_id.includes(
      item.value
    )
  );

  const clearFilter = (value) => {
    setSelectedFilters((prevFilters) => {
      return {
        ...prevFilters,
        aggregationFilter: {
          ...prevFilters.aggregationFilter,
          default_product_category_id:
            prevFilters.aggregationFilter.default_product_category_id.filter(
              (filterValue) => filterValue !== value
            ),
        },
      };
    });
  };

  return selectedOptions.length
    ? selectedOptions.map((selectedOption) => {
        return (
          <FilterTagsCustomFilters.FilterTag
            key={selectedOption.value}
            label={optionLabelCtx.renderLabel(
              filterSetting,
              selectedOption,
              labelOptions
            )}
            labelString={optionLabelCtx.renderLabelString(
              filterSetting,
              selectedOption,
              labelOptions
            )}
            onDismiss={() => clearFilter(selectedOption.value)}
            {...rest}
          />
        );
      })
    : null;
};

ProductCategoryFilterTag.propTypes = {
  filterSetting: PropTypes.object,
  selectedFilters: PropTypes.object,
};

export const productCategoryFilter = {
  field: 'default_product_category_id',
  label: 'Category',
  items: [
    // womens
    { value: 212, label: 'Outfits' },
    { value: 208, label: 'Bottoms' },
    { value: 207, label: 'Tops' },
    { value: 209, label: 'Bras' },
    { value: 1085, label: 'Shoes' },
    { value: 211, label: 'Accessories' },

    // mens
    { value: 1735, label: 'Bottoms' },
    { value: 1743, label: 'Tops' },
    { value: 1744, label: 'Underwear' },
    { value: 1786, label: 'Accessories' },
    { value: 1756, label: 'Outfits' },

    // scrubs
    { value: 2086, label: 'Womens Scrubs Bottoms' },
    { value: 2084, label: 'Womens Scrubs Tops' },
    { value: 2101, label: 'Womens Underscrubs' },
    { value: 2108, label: 'Womens Scrubs Jackets' },
    { value: 2165, label: 'Womens Scrubs Kits' },
    { value: 2087, label: 'Mens Scrubs Bottoms' },
    { value: 2097, label: 'Mens Scrubs Tops' },
    { value: 2106, label: 'Mens Underscrubs' },
    { value: 2109, label: 'Mens Scrubs Jackets' },
    { value: 2166, label: 'Mens Scrubs Kits' },
  ],
  fromUrlParams: ({ urlParams, filters }) => {
    const defaultProductCategoryFilters = urlParams.get(
      'default_product_category_id'
    );

    if (!defaultProductCategoryFilters) {
      return {
        ...filters,
        aggregationFilter: {
          ...filters.aggregationFilter,
          default_product_category_id: [],
        },
      };
    }

    const filterList = defaultProductCategoryFilters.split(',');
    const parsedCategoryIds = filterList.map((filter) => parseFloat(filter));

    return {
      ...filters,
      aggregationFilter: {
        ...filters.aggregationFilter,
        default_product_category_id: parsedCategoryIds,
      },
    };
  },
  FilterTagComponent: ProductCategoryFilterTag,
};

export const fplSortOption = {
  value: 'fplasc',
  sort: 'fplasc',
  defaultMessage: 'Recommended',
  isDefault: true,
};

export const getNewestSortOption = (filterLabel) => ({
  value: 'newest',
  // * Keyword sort automatically selects associated required fields in Tier1
  sort: 'newest',
  // * Array sort can be used to specify fields and directions explicitly
  // `newest` in Tier1 translates to:
  // [
  //   { field: 'date_expected', direction: 'desc' },
  //   { field: 'sales_count_10_days', direction: 'desc' },
  //   { field: 'review_count', direction: 'desc' },
  // ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Newest',
});

export const getBestSellersSortOption = (filterLabel) => ({
  value: 'bestsellers',
  sort: 'bestsellers',
  defaultMessage: 'Best Sellers',
});

export const getTopReviewedSortOption = (filterLabel) => ({
  value: 'topreviewed',
  sort: 'topreviewed',
  defaultMessage: filterLabel?.length ? filterLabel : 'Highest Rated',
});

const getSortField = ({ retailPriceSort, secondarySite }) => {
  if (secondarySite === SecondarySites.OUTLET) {
    return 'warehouse_unit_price';
  }
  if (retailPriceSort) {
    return 'retail_unit_price';
  }
  return 'default_unit_price';
};

export const getPriceLowToHighSortOption = ({
  retailPriceSort,
  filterLabel,
  secondarySite,
}) => ({
  value: 'pricel2h',
  sort: [
    {
      field: getSortField({ retailPriceSort, secondarySite }),
      direction: 'asc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Price: Low to High',
});

export const getPriceHighToLowSortOption = ({
  retailPriceSort,
  filterLabel,
  secondarySite,
}) => ({
  value: 'priceh2l',
  sort: [
    {
      field: getSortField({ retailPriceSort, secondarySite }),
      direction: 'desc',
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Price: High to Low',
});

export const getAlgoSortOption = (customSort, filterLabel) => ({
  value: customSort.field,
  sort: [
    {
      field: customSort.field,
      direction: customSort.direction,
    },
  ],
  defaultMessage: filterLabel?.length ? filterLabel : 'Recommended',
});

export const defaultSortOptions = ({ retailPriceSort, secondarySite }) => [
  { ...getNewestSortOption(), isDefault: true },
  getBestSellersSortOption(),
  getTopReviewedSortOption(),
  getPriceLowToHighSortOption({ retailPriceSort, secondarySite }),
  getPriceHighToLowSortOption({ retailPriceSort, secondarySite }),
];

export const parseSortOptionsFromSortList = ({
  sortList,
  retailPriceSort,
  secondarySite,
}) => {
  const sortOptions = sortList
    .map((sortListItem) => {
      if (sortListItem?.sortOption) {
        switch (sortListItem?.sortOption) {
          case 'newest':
            return getNewestSortOption(sortListItem.filterLabel);
          case 'bestsellers':
            return getBestSellersSortOption(sortListItem.filterLabel);
          case 'topReviewed':
            return getTopReviewedSortOption(sortListItem.filterLabel);
          case 'priceLowToHigh':
            return getPriceLowToHighSortOption({
              retailPriceSort,
              filterLabel: sortListItem.filterLabel,
              secondarySite,
            });
          case 'priceHighToLow':
            return getPriceHighToLowSortOption({
              retailPriceSort,
              filterLabel: sortListItem.filterLabel,
              secondarySite,
            });
          case 'fplSort':
            return {
              ...fplSortOption,
              isDefault: false,
              defaultMessage: sortListItem.filterLabel?.length
                ? sortListItem.filterLabel
                : 'Recommended',
            };
        }
      }

      if (sortListItem?.customSort?.field) {
        return getAlgoSortOption(
          sortListItem.customSort,
          sortListItem.filterLabel
        );
      }
      return null;
    })
    .filter((value) => value);

  // the first option is the default
  if (sortOptions?.length) {
    sortOptions[0].isDefault = true;
  }
  return sortOptions;
};

export const GridTypes = {
  Standard: 'standard',
  MoreLess: 'moreless',
};

export const ImageTypes = {
  Plus: 'plus',
  Default: 'default',
};

const MemberCreditDisplayPriceThresholds = {
  '.ca': 55,
  '.de': 35,
  '.dk': 275,
  '.es': 35,
  '.fr': 35,
  '.nl': 35,
  '.se': 400,
  '.co.uk': 30,
  '.com': 40,
};

export const getMemberCreditPriceThreshold = (domain) => {
  return MemberCreditDisplayPriceThresholds[domain] || 40;
};
