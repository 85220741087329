export function getProductGridItemFilters({
  categoryIds,
  tagIds: inputTagIds,
  fpls: inputFpls,
  fplCodes: inputFplCodes,
  excludeCategoryIds: inputExcludeCategoryIds,
  excludeTagIds: inputExcludeTagIds,
  excludeFpls: inputExcludeFpls,
  productTypeIds: inputProductTypeIds,
  includeOutOfStock = false,
  availableQtyMin,
  customFields,
}) {
  const backgroundCategoryIds = parseNodePropertyToArray(categoryIds);

  // tagIds is a weird array in an array in the api contract to tier 1
  // which is why it ends up structured as [[tag1, tag2]] different
  // than the rest of the paramters
  const tagIds = inputTagIds?.length
    ? [parseNodePropertyToArray(inputTagIds)]
    : [];
  const fpls = parseNodePropertyToArray(inputFpls);
  const fplCodes = parseNodePropertyToArray(inputFplCodes);
  const excludeCategoryIds = parseNodePropertyToArray(inputExcludeCategoryIds);

  const excludeTagIds = parseNodePropertyToArray(inputExcludeTagIds);
  const excludeFpls = parseNodePropertyToArray(inputExcludeFpls);
  const productTypeIds = parseNodePropertyToArray(inputProductTypeIds);

  const baseFilters = {
    aggregationFilter: {},
    backgroundCategoryIds,
    tagIds,
    fpls,
    fplCodes,
    excludeCategoryIds,
    excludeTagIds,
    excludeFpls,
    productTypeIds,
    imageSize: 1800,
    availableQtyMin: availableQtyMin,
    includeOutOfStock,
    ...customFields,
  };

  if (fplCodes.length) {
    baseFilters.backgroundFplCodes = fplCodes;
  }

  return baseFilters;
}

export function parseNodePropertyToArray(property) {
  if (!property) {
    return [];
  }

  if (Array.isArray(property)) {
    return property.map((item) => item.value);
  } else {
    return property.value ? [property.value] : [];
  }
}
