import { AggregationPairingsMap } from '../constants/aggregations';

/**
 * Builds a GraphQL query object for retrieving product dimensions based on the provided V2Request and options.
 *
 * @param {V2Request} v2Request - The V2Request object containing aggregations to be included in the query.
 * @param {ConvertOptions} options - Options for building the query object.
 * @private
 */
export const buildProductDimensionsQueryFragment = (v2Request) => {
  if (v2Request.aggregations) {
    // a map with all the aggregations to be returned from gql ProductDimensions query
    let dimensionsString = '';
    for (const element of v2Request.aggregations) {
      dimensionsString = `${dimensionsString}
        ${AggregationPairingsMap.get(element.field)} {
          id
          label
          options {
            label
            count
            value
            __typename
          }
          queryField
          __typename
        }`;
    }

    return `
    productDimensions(productQuery: $dimensionsProductQuery) {
      dimensions {
        ${dimensionsString}
        __typename
      }
      __typename
    }`;
  }
  return '';
};
