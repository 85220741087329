export const getSizesWithNeighbors = ({ size, gender }) => {
  const sizes =
    gender === 'M'
      ? [
          'X-Small',
          'Small',
          'Medium',
          'Large',
          'X-Large',
          'XX-Large',
          '2X',
          '3X',
          '4X',
        ]
      : [
          'XX-Small',
          'X-Small',
          'Small',
          'Medium',
          'Large',
          'X-Large',
          'XX-Large',
          '2X',
          '3X',
          '4X',
          '5X',
          '6X',
        ];

  const index = sizes.indexOf(size);
  if (index === -1) {
    return [size];
  }

  let neighbors;
  if (index === 0) {
    // First item
    neighbors = sizes.slice(index, index + 2); // Get first two sizes
  } else if (index === sizes.length - 1) {
    // Last item
    neighbors = sizes.slice(index - 1, index + 1); // Get last two sizes
  } else {
    // Middle items
    const prevSize = sizes[index - 1] || size;
    const nextSize = sizes[index + 1] || size;
    neighbors = [prevSize, size, nextSize];
  }

  return [...new Set(neighbors)].filter(Boolean);
};
