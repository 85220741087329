import config from 'config';

const LOCALES = Object.values(config.get('public.intl.locales'));

export const autoResolveBuilderLocale = (locale, json) => {
  return JSON.parse(
    JSON.stringify(json, (_, value) => {
      if (
        value?.['@type'] === '@builder.io/core:LocalizedValue' ||
        typeof value?.Default !== 'undefined' ||
        LOCALES.some((locale) => typeof value?.[locale] !== 'undefined')
      ) {
        return value[locale] || value.Default || '';
      }
      return value;
    })
  );
};
