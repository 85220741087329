import { AggregationPairingsMap } from '../constants/aggregations';

export const getProductGqlQueryParameters = ({
  productListing,
  useRetailDiscounting = false,
  shouldShowWarehouseProducts = false,
}) => {
  return {
    productQuery: {
      masterProductId: {
        in: productListing?.reduce(
          (acc, currentProduct) => [...acc, currentProduct?.masterProductId],
          []
        ),
      },
    },
    retailDiscounting: useRetailDiscounting,
    shouldShowWarehouseProducts,
  };
};

export const getProductGridQueryParameters = (
  v2Request,
  { includeProductDimensions = true } = {}
) => {
  // dimensions to be requested from productDimensions
  const dimensions = [];
  if (v2Request.aggregations) {
    for (const element of v2Request.aggregations) {
      if (element.field !== 'membership_brand_id') {
        dimensions.push(element.field);
      }
    }
  }

  const aggregationMap = new Map();
  if (v2Request.aggregationFilter) {
    for (const element in v2Request.aggregationFilter) {
      const filter = v2Request.aggregationFilter[element];
      if (Array.isArray(filter) && filter.length) {
        aggregationMap.set(AggregationPairingsMap.get(element), {
          in: filter,
        });
      }
    }
  }

  const dimensionsFilter = Object.fromEntries(aggregationMap);

  const tagId = {};
  const dimensionsTagId = {};
  if (v2Request.tagIds) {
    const tagIds = [];
    for (const prop in v2Request.tagIds) {
      if (v2Request.tagIds[prop].length) {
        tagIds.push(v2Request.tagIds[prop][0]);
      }
    }
    if (v2Request?.backgroundTagIds?.length) {
      dimensionsTagId.in = v2Request.backgroundTagIds;
    }
    if (tagIds.length) {
      tagId.in = dimensionsTagId.in = [...tagIds];
    }
  }

  if (
    v2Request.excludeTagIds?.length ||
    v2Request.availableQuantityIgnoreTagIds?.length
  ) {
    let excludeTagIds = [];
    excludeTagIds = excludeTagIds.concat(
      v2Request.excludeTagIds,
      v2Request.availableQuantityIgnoreTagIds
    );
    tagId.nin = dimensionsTagId.nin = excludeTagIds;
  }

  if (v2Request.defaultTagId) {
    if (tagId.in?.length) {
      tagId.in.push(v2Request.defaultTagId);
    } else {
      tagId.in = [v2Request.defaultTagId];
    }
  }

  const sort = transformV2SortValues(v2Request.sort, v2Request.boostedFplId);

  const review = {};
  if (v2Request.avgReviewMin) {
    review.averageRating = {};
    review.averageRating.gte = v2Request.avgReviewMin;
  }

  if (v2Request.avgReviewMax) {
    if (!review.averageRating) {
      review.averageRating = {};
    }
    review.averageRating.lte = v2Request.avgReviewMax;
  }

  const dateExpected = {};
  if (v2Request.dateExpectedMin) {
    dateExpected.gte = v2Request.dateExpectedMin;
  }

  if (v2Request.dateExpectedMax) {
    dateExpected.lte = v2Request.dateExpectedMax;
  }

  const masterQuantityInStock = {};
  if (v2Request.availableQtyMin) {
    masterQuantityInStock.gte = v2Request.availableQtyMin;
  }

  if (v2Request.includeOutOfStock === false) {
    masterQuantityInStock.gt = 0;
  }

  const categoryId = {};
  const dimensionsCategoryId = {};
  if (v2Request.backgroundCategoryIds?.length) {
    categoryId.in = dimensionsCategoryId.in = [
      ...v2Request.backgroundCategoryIds,
    ];
  }

  if (v2Request.categoryIds?.length) {
    categoryId.in = [...v2Request.categoryIds];
    if (isEmpty(dimensionsCategoryId)) {
      dimensionsCategoryId.in = [...v2Request.categoryIds];
    }
  }

  if (v2Request.excludeCategoryIds?.length) {
    categoryId.nin = v2Request.excludeCategoryIds;
  }

  const featuredProductLocationCode = {};
  const dimensionsFeaturedProductLocationCode = {};
  if (
    v2Request.fplCodes?.length ||
    v2Request.activeFeaturedProductLocationCodes?.length ||
    v2Request.backgroundFplCodes?.length
  ) {
    featuredProductLocationCode.in = [
      ...(v2Request.fplCodes || []),
      ...(v2Request.activeFeaturedProductLocationCodes || []),
      ...(v2Request.backgroundFplCodes || []),
    ];
    dimensionsFeaturedProductLocationCode.in = [
      ...(v2Request.backgroundFplCodes || []),
    ];
  }

  const featuredProductLocationId = {};
  const dimensionsFeaturedProductLocationId = {};
  if (v2Request?.backgroundFplIds?.length) {
    featuredProductLocationId.in = dimensionsFeaturedProductLocationId.in = [
      ...v2Request.backgroundFplIds,
    ];
  }

  if (v2Request?.fpls?.length) {
    featuredProductLocationId.in = dimensionsFeaturedProductLocationId.in = [
      ...v2Request.fpls,
    ];
  }

  if (
    v2Request.excludeFpls?.length ||
    v2Request.excludeActiveFeaturedProductLocationIds?.length
  ) {
    featuredProductLocationId.nin = dimensionsFeaturedProductLocationId.nin = [
      ...(v2Request.excludeFpls || []),
      ...(v2Request.excludeActiveFeaturedProductLocationIds || []),
    ];
  }

  const masterProductId = {};
  if (v2Request.masterProductIds?.length) {
    masterProductId.in = v2Request.masterProductIds;
  }

  const membershipBrandId = {};
  if (v2Request.membershipBrandIds?.length) {
    membershipBrandId.in = v2Request.membershipBrandIds;
  }

  const productTypeId = {};
  if (v2Request.productTypeIds?.length) {
    productTypeId.in = v2Request.productTypeIds;
  }

  const tagLabel = {};
  if (v2Request.tags?.length) {
    tagLabel.in = v2Request.tags;
  }

  const warehouseId = {};
  if (v2Request.warehouseId?.length) {
    warehouseId.in = v2Request.warehouseId;
  }

  const membershipLevelGroup = getMembershipLevelGroup(
    v2Request.membershipLevelGroup
  );

  let from = 0;
  if (v2Request.page > 1 && v2Request.size) {
    const gqlPage = v2Request.page * v2Request.size - v2Request.size;
    from = gqlPage;
  }

  const preorder = {};
  if (v2Request?.availableQtyMaxIncludePreorder) {
    preorder.availableQty = { gte: 1 };
  }

  if (v2Request?.excludePreorderProducts) {
    preorder.exclude = v2Request.excludePreorderProducts;
  }

  const productQueryRaw = {
    categoryId: !isEmpty(categoryId) ? categoryId : null,
    dateExpected: !isEmpty(dateExpected) ? dateExpected : null,
    dimensions: !isEmpty(dimensionsFilter) ? dimensionsFilter : null,
    featuredProductLocationCode: !isEmpty(featuredProductLocationCode)
      ? featuredProductLocationCode
      : null,
    featuredProductLocationId: !isEmpty(featuredProductLocationId)
      ? featuredProductLocationId
      : null,
    masterProductId: !isEmpty(masterProductId) ? masterProductId : null,
    masterQuantityInStock: !isEmpty(masterQuantityInStock)
      ? masterQuantityInStock
      : null,
    membershipBrandId: !isEmpty(membershipBrandId) ? membershipBrandId : null,
    preorder: !isEmpty(preorder) ? preorder : null,
    productTypeId: !isEmpty(productTypeId) ? productTypeId : null,
    review: !isEmpty(review) ? review : null,
    tagId: !isEmpty(tagId) ? tagId : null,
    tagLabel: !isEmpty(tagLabel) ? tagLabel : null,
    warehouseId: !isEmpty(warehouseId) ? warehouseId : null,
    membershipLevelGroup: !isEmpty(membershipLevelGroup)
      ? membershipLevelGroup
      : null,
    // and:
    // or:
  };

  const productQuery = Object.entries(productQueryRaw)
    .filter(([key, value]) => value !== null)
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  let dimensionsProductQueryRaw = null;
  if (includeProductDimensions) {
    dimensionsProductQueryRaw = {
      ...productQuery,
      dimensions: undefined,
      tagId: !isEmpty(dimensionsTagId) ? dimensionsTagId : null,
      categoryId: !isEmpty(dimensionsCategoryId) ? dimensionsCategoryId : null,
      featuredProductLocationCode: !isEmpty(
        dimensionsFeaturedProductLocationCode
      )
        ? dimensionsFeaturedProductLocationCode
        : null,
      featuredProductLocationId: !isEmpty(dimensionsFeaturedProductLocationId)
        ? dimensionsFeaturedProductLocationId
        : null,
    };
  }

  const dimensionsProductQuery = dimensionsProductQueryRaw
    ? Object.entries(dimensionsProductQueryRaw)
        .filter(([key, value]) => value !== null)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {})
    : {};

  return {
    variables: {
      from,
      sort,
      productQuery,
      dimensionsProductQuery,
      size: v2Request.size ? v2Request.size : 8,
    },
    operationName: 'ProductListing',
  };
};

function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

/**
 * Transforms a given v2Sort or v2Sort[] into its GraphQL (GQL) representation
 * for sorting purposes.
 *
 * @param {v2Sort[] | string} v2Sort - The v2Sort or array of v2Sort to transform.
 * @param {number} boostedFplId - The boosted field ID, if applicable.
 * @returns {Sort} The transformed GqlSort object representing the sorting criteria.
 */
export function transformV2SortValues(v2Sort, boostedFplId) {
  const sort = {};
  const values = [];
  if (v2Sort) {
    if (Array.isArray(v2Sort)) {
      for (const item of v2Sort) {
        if (item) {
          values.push({ field: item.field, order: item.direction });
        }
      }
    } else {
      sort.value = v2Sort.toString().toLowerCase();
    }
  }

  if (boostedFplId) {
    sort.boostedField = { fplId: boostedFplId };
  }
  if (values.length) {
    sort.values = values;
  }
  return sort;
}

/**
 * Gets the membership level group based on the provided V2 membership level group string.
 *
 * @param {string} v2MembershipLevelGroup - The V2 membership level group string.
 * @returns {MembershipLevelGroups} The membership level group object.
 */
export function getMembershipLevelGroup(v2MembershipLevelGroup) {
  const membershipLevelGroup = {};
  switch (v2MembershipLevelGroup) {
    case 'elite':
      membershipLevelGroup.elite = true;
      break;
    case 'lead':
      membershipLevelGroup.lead = true;
      break;
    case 'vip':
      membershipLevelGroup.vip = true;
      break;
    case 'visitor':
      membershipLevelGroup.visitor = true;
      break;
  }
  return membershipLevelGroup;
}
